import { postData } from '../util/api';

interface Message {
  id: number;
  content: string;
  role: 'user' | 'assistant';
}

/**
 * Sends a request to the server to log in a user
 * @param messages The messages in the convo
 */

function getUrl(url: string) {
  const BACKENDURL = 'https://gpt4-1-backend.onrender.com'; 
  // const BACKENDURL = 'http://localhost:4000';
  // process.env.BACKEND
  //   ? process.env.BACKEND
  //   : 'http://localhost:4000';
  const URLPREFIX = `${BACKENDURL}/api`;
  return `${URLPREFIX}/${url}`;
}

// Returns the assistant's response message
async function postConvo(
  messages: Message[],
  email: string | null,
  curIndex: number,
  setMessages: any,
  conversationIndex: number,
) {
  const url = getUrl('chat/conversation');
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ messages, email }),
  });

  if (!response.ok || !response.body) {
    if (response.status === 402) throw new Error('too few tokens');
    else if (response.status === 400)
      throw new Error(
        'Input error: Make sure your message is not too long, and non-empty',
      );
    else if (response.status === 401)
      throw new Error(
        'Authorization error: make sure you have authorized your email',
      );
    else if (response.status === 500)
      throw new Error('Server error: please try again later');
    else
      throw new Error(
        'Network error: response was not ok / no body on response',
      );
  }

  const reader = response.body.getReader();
  const decoder = new TextDecoder('utf-8');

  // Read and process the chunks as they arrive
  let numLines = 0;
  let messageToReturn: any;
  let seenDone = false;
  while (true) {
    const { done, value } = await reader.read();

    if (done) { // || seenDone -> seemed to exit prematurely so only stop reading on done
      break;
    }

    const chunk = decoder.decode(value);
    const lines = chunk.split('\n').filter(Boolean); // Split by newline and filter out empty strings

    for (const line of lines) {
      const data = JSON.parse(line);
      // console.log(data); // Here, process each chunk as needed.
      const { text, tokensLeft } = data;
      if (numLines === 0) {
        const gptMessage: Message = {
          id: Date.now(),
          content: text,
          role: 'assistant',
        };
        console.log('Setting first message to ', gptMessage);
        setMessages([...messages, gptMessage]);
        messageToReturn = gptMessage.content;
      } else {
        if (text === '[DONE]') {
          // Push the last message to the database and return
          console.log("Going to setDone to True, text = ", text); 
          seenDone = true;
          break;
        }
        setMessages((prevMessages: any) => {
          // Clone the last message and update its content
          const lastMessage = { ...prevMessages[curIndex] };
          // console.log('lastMessage: ', lastMessage.content);
          lastMessage.content += text;
          messageToReturn = lastMessage.content;
          // Return a new array with all the old messages except the last one, followed by the updated last message
          return [...prevMessages.slice(0, -1), lastMessage];
        });
      }
      numLines += 1;
    }
  }
  console.log("Pushing last message to database: ", messageToReturn);
  const chatPageResponse: Message = {
    id: Date.now(),
    content: messageToReturn!,
    role: 'assistant',
  };
  addMessageToDb(email, conversationIndex, chatPageResponse);
  return messageToReturn!;
}

async function createNewConversation(
  title: string | null,
  email: string | null,
) {
  const res = await postData('chat/create', {
    email,
    title,
  });
  if (res.error) {
    throw Error(res.error.data.error);
  }
  return res.data;
}

async function loadConversation(
  email: string | null,
  conversationIndex: number,
) {
  const res = await postData('chat/load', {
    email,
    conversationIndex,
  });
  if (res.error) {
    throw Error(res.error.data.error);
  }
  return res.data;
}

async function removeConversation(
  email: string | null,
  conversationIndex: number,
) {
  const res = await postData('chat/deleteconvo', {
    email,
    conversationIndex,
  });
  if (res.error) {
    throw Error(res.error.data.error);
  }
  return res.data;
}

async function payment(amount: number, email: string | null) {
  const items = [{ id: 0, quantity: amount }];
  const res = await postData('payment/create-checkout-session', {
    items,
    email,
  });
  if (res.error) {
    throw Error(res.error.data.error);
  }
  return res.data;
}

/**
 * Makes a request to the server to upgrade a self to admin from the current session
 * @returns true if successful, false otherwise
 * PLEASE REMOVE THIS FUNCTION AND BACKEND ENDPOINT UPON DEPLOYMENT
 */
async function getUserObject(email: string) {
  const res = await postData('auth/get-user', { email });
  if (res.error) return -1;
  return res.data;
}

async function addMessageToDb(
  email: string | null,
  index: number,
  message: Message,
) {
  const res = await postData('chat/message', {
    email,
    index,
    message,
  });
  if (res.error) {
    throw Error(res.error.data.error);
  }
  return res.data;
}

// eslint-disable-next-line import/prefer-default-export
export {
  postConvo,
  getUserObject,
  payment,
  addMessageToDb,
  createNewConversation,
  loadConversation,
  removeConversation,
};
export type { Message };
