/**
 * A file for defining the colors used in the project.
 */
const COLORS = {
  primaryGreen: '#00A67E', // ChatGPT Green
  secondarySeafoam: '#80d2c8',
  gray: '#657788',
  primaryDark: '#00664e', // Dark Green
  primaryDark2: '#004d4d', // Dark Blue level 2
  background: '#d7fdff', // Light Light Blue
  accentOrange: '#f2594b',
  black: '#171717', // Black
  white: '#ffffff', // White
  neutral: '#8c8c8c', // Gray
  lightGray: '#ebeff2', // Gray-subsection
  actionBlue: '#155da1', // Action button

  // Other colors:
};
export default COLORS;
