import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import ScreenGrid from '../components/ScreenGrid';
// import InviteUserButton from '../components/buttons/InviteUserButton';
// eslint-disable-next-line import/named
import {
  Box,
  Button,
  Grid,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Message,
  postConvo,
  addMessageToDb,
  createNewConversation,
  loadConversation,
  removeConversation,
} from './api';
import { useAppDispatch, useAppSelector } from '../util/redux/hooks';
import { selectUser, updateChatTokens } from '../util/redux/userSlice';
import ScreenGrid from '../components/ScreenGrid';
import { AlertDialogWithOption } from '../components/AlertDialog';
import { fontSize } from '@mui/system';
import '../chat.css';
import { postData } from '../util/api';
import { TramRounded } from '@material-ui/icons';
import CircularProgress from '@mui/material/CircularProgress';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
const theme = createTheme();

const customBreakpointTheme = createTheme({
  breakpoints: {
    values: {
      xs: 237,
      sm: 360, // Set 360 if we want to only allow subheading one line, 250 for two lines
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

const useStyles = makeStyles(() => ({
  chatpageContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  chatpageHeader: {
    backgroundColor: '#ffffff',
    padding: 16,
    textAlign: 'center',
    // position: 'fixed',
    // width: '100%'
  },
  chatpageMessages: {
    flexGrow: 2,
    padding: 16,
    height: 'calc( 100% - 215px )',
    overflowY: 'auto',
    flexDirection: 'column',
  },
  messageBubbleUser: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 12px',
    marginBottom: 8,
    borderRadius: 8,
    width: '90%',
    backgroundColor: '#d0f4e9',
    color: '#00a67e',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
    textAlign: 'right',
  },
  messageBubbleChatpage: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 12px',
    marginBottom: 8,
    borderRadius: 8,
    width: '90%',
    backgroundColor: '#f8f9fa',
    color: '#212529',
    justifyContent: 'flex-start',
  },
  chatpageInput: {
    display: 'flex',
    position: 'relative',
    alignItems: 'stretch',
    marginLeft: '15px',
    marginRight: '15px',
    marginTop: '15px',
    marginBottom: '15px',
  },
  chatInputContainer: {
    flex: 1,
    position: 'relative',
    // minHeight: '1em', // Set a minimum height to ensure upward growth.
  },
  chatInput: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  chatpageInputButton: {
    // Your button styles here
  },
  chatpageInputButtonHover: {
    backgroundColor: '#d0f4e9',
  },
  codeBlock: {
    backgroundColor: '#1a1a1a',
    color: '#f5f5f5',
    padding: 10,
    marginBottom: 10,
    fontFamily: "'Courier New', Courier, monospace",
    fontSize: 14,
    lineHeight: 1.4,
  },
  keyword: {
    color: '#007bff',
    fontWeight: 'bold',
  },
  string: {
    color: '#e83e8c',
  },
  comment: {
    color: '#888',
    fontStyle: 'italic',
  },
}));

/**
 * A page only accessible to authenticated users that allows you to chat with ChatGPT-4.
 */
// eslint-disable-next-line react/function-component-definition
const ChatPage: React.FC = () => {
  const navigate = useNavigate();
  const [classes, setClasses] = useState<any>(useStyles());
  const [showSidebar, setShowSidebar] = useState(false); // Default to true for computer, false for mobile
  const [showSidebarFromResize, setShowSidebarFromResize] = useState(false);

  const [forceRerender, setForceRerender] = useState(0);

  const typographyRef = useRef(null);
  const [typographyHeight, setTypographyHeight] = useState(25);

  const updateTypographyHeight = () => {
    if (
      typographyRef.current &&
      window.innerWidth >= customBreakpointTheme.breakpoints.values.xs
    ) {
      console.log(
        'Setting height, ' +
          (typographyRef.current as HTMLElement).offsetHeight,
      );
      setTypographyHeight((typographyRef.current as HTMLElement).offsetHeight);
    } else if (
      window.innerWidth < customBreakpointTheme.breakpoints.values.xs
    ) {
      setTypographyHeight(0);
    }
  };

  const updateShowSidebarFromResize = () => {
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);

    if (isMobile) {
      console.log('is mobile!');
      setShowSidebarFromResize(true);
    } else {
      if (window.innerWidth >= theme.breakpoints.values.sm)
        setShowSidebarFromResize(true);
      else setShowSidebarFromResize(false);
    }
  };

  useEffect(() => {
    setForceRerender((prevKey) => prevKey + 1);
    updateTypographyHeight(); // Update initially
    updateShowSidebarFromResize(); // Update initially
    window.addEventListener('resize', updateTypographyHeight); // Update on resize
    window.addEventListener('resize', updateShowSidebarFromResize); // Update on resize

    // Cleanup
    return () => {
      window.removeEventListener('resize', updateTypographyHeight);
    };
  }, []);

  const chatpageMessagesHeight = `calc(100% - ${typographyHeight + 195}px)`;

  // Default values for state

  const defaultShowErrors = {
    email: false,
    password: false,
    alert: false,
  };
  const defaultErrorMessages = {
    email: '',
    password: '',
    alert: '',
  };
  const defaultAlertTitle = 'Not enough tokens!';

  // State values and hooks
  const [showError, setShowErrorState] = useState(defaultShowErrors);
  const [errorMessage, setErrorMessageState] = useState(defaultErrorMessages);
  const [alertTitle, setAlertTitle] = useState(defaultAlertTitle);

  // Helper functions for changing only one field in a state object
  const setShowError = (field: string, show: boolean) => {
    setShowErrorState((prevState) => ({
      ...prevState,
      ...{ [field]: show },
    }));
  };

  const handleAlertClose = () => {
    setShowError('alert', false);
  };

  const setErrorMessage = (field: string, msg: string) => {
    setErrorMessageState((prevState) => ({
      ...prevState,
      ...{ [field]: msg },
    }));
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const user = useAppSelector(selectUser);
  const [isNewConvo, setIsNewConvo] = useState<Boolean>(true);
  const [isLoadingChat, setIsLoadingChat] = useState<Boolean>(false); // True when switching conversations
  const [conversations, setConversations] = useState<any>([]);
  const [acceptInput, setAcceptInput] = useState<Boolean>(true); // False when streaming response from gpt
  const [messages, setMessages] = useState<Message[]>([]);
  // object that we set when we begin streaming
  const [inputValue, setInputValue] = useState('');
  // TODO: handle conversation index becoming too negative
  const [conversationIndex, setConversationIndex] = useState(-1);
  const [reload, setReload] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    createNewConversation(null, user.email);
    console.log('!Just loaded');

    async function loadChatHistory() {
      try {
        /* Get Convos - Need to load at beginning */
        const res = await postData('chat/getconvos', {
          email: user.email,
        });
        setConversations(res.data);
        console.log('convos:', JSON.stringify(res.data));
      } catch (error) {
        console.error('Failed to load chatHistory:', error);
      }
    }

    loadChatHistory();
  }, [user.email, reload]);

  useEffect(() => {
    console.log('Selected useeffect convo with id ', conversationIndex);
    if (!acceptInput) return; // Don't allow chat switch if currently streaming

    if (conversationIndex >= 0) setIsLoadingChat(true);
    loadConversation(user.email, conversationIndex)
      .then((previousMessages) => {
        setIsLoadingChat(false);
        setMessages(previousMessages.messages);
        setInputValue('');
      })
      .catch((error) => {
        setIsLoadingChat(false);
        console.error('Error in loadConversation:', error.message);
      });
  }, [conversationIndex, reload, user.email]);

  useEffect(() => {
    if (messages.length === 0) return; // Skip if there are no messages

    const latestMessage = messages[messages.length - 1]; // Get the latest message

    // Scroll to the bottom
    if (latestMessage) {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }

    // Check if the latest message is from the user
    if (latestMessage.role === 'user') {
      // Call your chatpage API or processing function here to generate a response
      // and add it to the messages array with the sender set to 'chatpage'.
      const loadingResponse: Message = {
        id: Date.now(),
        content: 'Loading...',
        role: 'assistant',
      };
      setMessages([...messages, loadingResponse]);
      setAcceptInput(false);
      postConvo(
        messages,
        user.email,
        messages.length,
        setMessages,
        conversationIndex,
      )
        .then((response) => {
          console.log('response: ', response);
          setAcceptInput(true);
        })
        .catch((error) => {
          setAcceptInput(true);
          console.error('Error in postConvo:', error.message);
          if (error?.message === 'too few tokens') {
            // Handle the specific error case when there are too few tokens
            setShowError('alert', true);
            setAlertTitle('Not enough tokens!');
            setErrorMessage(
              'alert',
              `Thank you for using GPT 4.1. \nWe are charged for every message you send, so to keep using our service, 
              please enter payment information. \nBut good news! With our system, you can pay only for what you use, 
              making it far cheaper than subscribing directly to GPT4 for most users.`,
            );
          } else if (error?.message.includes('error')) {
            // Handle other error cases
            setShowError('alert', true);
            setAlertTitle('Error!');
            setErrorMessage('alert', error.message);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]); // Run the effect whenever the messages state changes

  const handleFormSubmit = async (
    event:
      | React.FormEvent<HTMLFormElement>
      | React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (!acceptInput || isLoadingChat) return;

    event.preventDefault();

    if (inputValue.trim() === '') return;

    const newMessage: Message = {
      id: Date.now(),
      content: inputValue.trim(),
      role: 'user',
    };
    setMessages([...messages, newMessage]);
    addMessageToDb(user.email, conversationIndex, newMessage);
    setInputValue('');
  };

  const handleNew = async () => {
    if (!acceptInput) return; // Don't allow chat switch if currently streaming

    setIsNewConvo(true);
    console.log('\nNew messages\n');
    console.log(conversationIndex);
    setConversationIndex(-1);
    setMessages([]);
    setReload(true);
  };

  const handlePrevious = async () => {
    if (!acceptInput) return;

    setIsNewConvo(false);
    console.log('\nPrevious messages\n');
    console.log(conversationIndex);
    loadConversation(user.email, conversationIndex - 1)
      .then((previousMessages) => {
        console.log(`Previous messages: ${JSON.stringify(previousMessages)}`);
        setMessages(previousMessages.messages);
        setConversationIndex(conversationIndex - 1);
        setInputValue('');
      })
      .catch((error) => {
        console.error('Error in loadConversation:', error.message);
      });
  };

  const handleDelete = async () => {
    console.log('\n Delete convo\n');
    console.log(conversationIndex);
    removeConversation(user.email, conversationIndex)
      .then(() => {
        setReload(!reload);
        setMessages([]);
        setConversationIndex(-1);
        setInputValue('');
      })
      .catch((error) => {
        console.error('Error in deleteConversation:', error.message);
      });
  };
  const linkStyle = {
    textDecoration: 'none',
    color: 'black',
    ':hover': {
      color: 'green',
    },
  };
  return (
    <ScreenGrid>
      <Grid
        container
        display="flex"
        flex-wrap="nowrap"
        overflow-x="auto"
        direction="row"
        height="100%"
      >
        {/* Add the Sidebar here */}
        {showSidebar && showSidebarFromResize ? (
          // <Hidden only={ showSidebar? ['xs', 'sm'] : ['xs', 'sm', 'md', 'lg', 'xl']}>
          <Grid item sm={3} maxWidth="250px" height="100%">
            {' '}
            {/* Sidebar occupies 3/12 (or 25%) of the screen width */}
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column', // vertical stacking
                // backgroundColor: '#f8f8f8',
                border: '1px solid #f8f8f8',
                height: '100%',
                padding: '20px',
              }}
            >
              <Box
                style={{
                  // display: 'flex',
                  position: 'sticky', // make the buttons stick
                  top: '0px', // top distance from viewport when scrolling
                  zIndex: 10,
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '15px 15px 0 0',
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    className="send-button"
                    style={{
                      marginLeft: '15px',
                      marginTop: '15px',
                      padding: '0px, 20px, 0px, 20px',
                    }} // Add margin to the left and top
                    onClick={handleNew}
                  >
                    New Chat
                  </Button>
                  <IconButton
                    edge="end"
                    color="inherit"
                    aria-label="menu"
                    onClick={(e) => setShowSidebar(false)}
                  >
                    <MenuIcon />
                  </IconButton>
                </Box>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '0px 15px 0 0',
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    className="send-button"
                    style={{ marginLeft: '15px', marginTop: '15px' }} // Add margin to the left and top
                    onClick={handleDelete}
                  >
                    Delete Current
                  </Button>
                </Box>
              </Box>
              <Box
                style={{
                  overflowY: 'auto', // Apply scroll to this container only
                  flex: 1, // Take the remaining height
                  margin: '15px',
                }}
              >
                <ul
                  style={{ listStyleType: 'none', padding: '0', margin: '0px' }}
                >
                  {[...conversations]
                    .reverse()
                    .map((convo: any, index: any) => (
                      <li
                        key={conversations.length - 1 - index}
                        className={
                          conversations.length - 1 - index === conversationIndex
                            ? 'selected'
                            : ''
                        }
                        onClick={() => {
                          if (!acceptInput) return; // Don't allow chat switch when streaming

                          console.log(JSON.stringify(convo));
                          console.log(
                            'Selected convo with id ',
                            conversations.length - 1 - index,
                          );
                          setConversationIndex(
                            conversations.length - 1 - index,
                          );
                        }}
                      >
                        {convo?.title}
                      </li>
                    ))}
                </ul>
              </Box>
              <Box
                style={{
                  // display: 'flex',
                  position: 'sticky', // make the buttons stick
                  top: '0px', // top distance from viewport when scrolling
                  zIndex: 10,
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '0px 15px 0 0',
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    className="send-button"
                    style={{
                      marginLeft: '15px',
                      marginTop: '15px',
                      width: '100%',
                      backgroundColor: '#000000',
                    }} // Add margin to the left and top
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    {user.firstName + ' ' + user.lastName}
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    style={{ color: '#00000' }}
                  >
                    <MenuItem
                      onClick={(e) => {
                        navigate('/pay', { replace: false });
                        handleClose();
                      }}
                      // style={{ color: '#ffffff', backgroundColor: '#000000' }}
                    >
                      Buy Chat Tokens
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => {
                        navigate('/home', { replace: false });
                        handleClose();
                      }}
                      // style={{ color: '#ffffff', backgroundColor: '#000000' }}
                    >
                      Home
                    </MenuItem>
                    {/* <MenuItem
                      onClick={(e) => {
                        handleLogout();
                        handleClose();
                      }}
                      style={{ color: '#ffffff', backgroundColor: '#000000' }}
                    >
                      Logout
                    </MenuItem> */}
                  </Menu>
                </Box>
              </Box>
            </Box>
          </Grid>
        ) : (
          <div></div>
        )}
        <Grid item xs={12} sm={showSidebar ? 9 : 12} height="100%">
          {' '}
          {/* Chat page occupies 9/12 (or 75%) of the screen width */}
          <Grid
            container
            display="block"
            flex-wrap="nowrap"
            direction="column"
            className={classes.chatpageContainer}
          >
            <Grid item className={classes.chatpageHeader}>
              <Grid container alignItems="center" display="contents">
                {(!showSidebar || !showSidebarFromResize) && (
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={(e) => setShowSidebar(true)}
                    style={{ position: 'absolute', left: '15px' }}
                  >
                    <MenuIcon />
                  </IconButton>
                )}
                <Link to="/home" style={linkStyle}>
                  <Typography
                    variant="h4"
                    component="h1"
                    className="gpt-heading"
                    margin="10px"
                  >
                    <Link to="/home" className="gpt-link">
                      GPT 4.1
                    </Link>
                  </Typography>
                </Link>
              </Grid>
              <ThemeProvider theme={customBreakpointTheme}>
                <Hidden smDown>
                  <Typography
                    ref={typographyRef}
                    variant="body1"
                    className="gpt-description"
                  >
                    Your Personal AI Assistant. Powered by GPT4
                  </Typography>
                </Hidden>
                <Hidden smUp xsDown>
                  <Typography
                    ref={typographyRef}
                    variant="body1"
                    className="gpt-description"
                  >
                    Your Personal AI Assistant
                  </Typography>
                </Hidden>
              </ThemeProvider>
            </Grid>
            <Grid
              item
              key={forceRerender}
              className={classes.chatpageMessages}
              style={{ height: chatpageMessagesHeight }}
            >
              {isLoadingChat ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {' '}
                  <CircularProgress size={50} />{' '}
                </div>
              ) : (
                messages.map((message) => {
                  let startIndex = -1;
                  return (
                    <div
                      key={message.id}
                      className={
                        message.role === 'user'
                          ? classes.messageBubbleUser
                          : classes.messageBubbleChatpage
                      }
                    >
                      {/* Replace "\n" with line breaks */}
                      {message.content
                        ?.split('\n')
                        .map((line, index, lines) => {
                          if (line.startsWith('```') && index !== startIndex) {
                            startIndex = lines.indexOf('```', index + 1);
                            return (
                              <pre className={classes.codeBlock}>
                                {lines
                                  .slice(lines.indexOf(line) + 1, startIndex)
                                  .join('\n')}
                              </pre>
                            );
                          }
                          if (index > startIndex) {
                            return (
                              <>
                                {line}
                                <br />
                              </>
                            );
                          }
                          return null;
                        })}
                    </div>
                  );
                })
              )}
              <div ref={ref} />
            </Grid>
            <form className={classes.chatpageInput} onSubmit={handleFormSubmit}>
              <div className={classes.chatInputContainer}>
                <TextField
                  type="text"
                  placeholder="Type your message..."
                  value={inputValue}
                  onChange={handleInputChange}
                  className={classes.chatInput}
                  multiline
                  maxRows={6}
                  style={{
                    marginRight: '8px',
                    position: 'absolute',
                    bottom: 0,
                    backgroundColor: '#FFFFFF',
                  }}
                  onKeyDown={async (e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault(); // Prevent inserting a new line
                      await handleFormSubmit(e); // Manually submit the form
                    }
                  }}
                />
              </div>

              <Button
                type="submit"
                variant="contained"
                className={classes.chatpageInputButton}
                disableElevation
                disabled={!acceptInput || isLoadingChat === true}
                style={{
                  marginLeft: '8px',
                  padding: '15px 0px 15px 0px',
                }}
              >
                Send
              </Button>
            </form>
          </Grid>
          <Grid item>
            <AlertDialogWithOption
              showAlert={showError.alert}
              title={alertTitle}
              message={errorMessage.alert}
              onClose={handleAlertClose}
            />
          </Grid>
        </Grid>
      </Grid>
    </ScreenGrid>
  );
};

// export default chatpage;
export default ChatPage;
