import React, { useEffect, useState } from 'react';
import { Link, NavigateFunction, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../util/redux/hooks';
import { logout as logoutApi, selfUpgrade } from '../Home/api';
import {
  logout as logoutAction,
  toggleAdmin,
  selectUser,
  updateChatTokens,
} from '../util/redux/userSlice';
import ScreenGrid from '../components/ScreenGrid';
import InviteUserButton from '../components/buttons/InviteUserButton';
// eslint-disable-next-line import/named
import { Message, payment, getUserObject } from '../Chat/api';
import './payment.css';
import PrimaryButton from '../components/buttons/PrimaryButton';
import '../chat.css';
import COLORS from '../assets/colors';

const PRICE_PER_THOUSAND_TOKENS = 0.05; // in dollars

const CHARS_PER_TOKEN = 4;
const CHARS_PER_WORD = 5;
const WORDS_PER_TOKEN = CHARS_PER_TOKEN / CHARS_PER_WORD;

const MIN_TOKENS = 0.75 / PRICE_PER_THOUSAND_TOKENS; // in thousands
const DEFAULT_TOKENS = 2.0 / PRICE_PER_THOUSAND_TOKENS; // in thousands
const MAX_TOKENS = 1000 / PRICE_PER_THOUSAND_TOKENS; // in thousands
const BLURB = `We sell GPT-4 tokens at a cost of $0.05 per 1000 tokens.
Each token is roughly a word (4 characters), and this paragraph is ~26 tokens.
Using our system, can pay for what you use rather than the flat $20 a
month OpenAI offers. You may buy up to 1,000,000 tokens at a time
(1000 sets of 1000 tokens). Tokens expire 1 year after purchase. Thank
you for using GPT 4.1!`;
interface PromoteButtonProps {
  admin: boolean | null;
  handleSelfPromote: () => void;
  navigator: NavigateFunction;
}

/**
 * A page only accessible to authenticated users that allows you to chat with ChatGPT-4.
 */
// eslint-disable-next-line react/function-component-definition
const Payment: React.FC = () => {
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  console.log(user);
  const [inputValue, setInputValue] = useState(DEFAULT_TOKENS.toString());
  const navigator = useNavigate();
  const dispatch = useAppDispatch();
  const [disabled, setDisabled] = useState<boolean>(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps, @typescript-eslint/no-shadow

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInputValue(value);

    // check if the value is a valid integer within the range
    const isValid =
      Number.isInteger(parseInt(value, 10)) &&
      parseInt(value, 10) >= MIN_TOKENS &&
      parseInt(value, 10) <= MAX_TOKENS;

    // update the disabled state based on the validity of the input
    setDisabled(!isValid);
  };

  const pay = async (quantity: number) => {
    payment(quantity, user.email)
      .then((data) => {
        console.log(data);
        window.location = data.url;
      })
      .catch((error) => {
        console.log('error in pay: ', error);
      });
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const dispatchTokens = (numChatTokens: number) => {
      dispatch(
        updateChatTokens({
          email: user.email!,
          firstName: user.firstName!,
          lastName: user.lastName!,
          admin: user.admin!,
          numChatTokens,
        }),
      );
    };
    getUserObject(user.email!)
      .then((response) => {
        console.log(response);
        dispatchTokens(response.numChatTokens);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [
    dispatch,
    user.firstName,
    user.lastName,
    user.numChatTokens,
    user.email,
    user.admin,
  ]); // Empty dependency array to only run the effect once

  return (
    <div className="usersettings-container">
      <Box className="usersettings-header" marginBottom="30px">
        <Typography
          variant="h4"
          component="h1"
          className="gpt-heading"
          margin="10px"
        >
          <Link to="/home" className="gpt-link">
            GPT 4.1
          </Link>
        </Typography>
        <Typography variant="h4" component="h2" className="page-title">
          Buy More Tokens
        </Typography>
        <Typography component="p" className="text">
          <i>Use GPT4 for as low as $0.75</i>
        </Typography>
      </Box>
      <Box className="usersettings-header">
        <Typography component="p" className="text" margin="10px">
          <Tooltip title={`= ${user.numChatTokens ? Math.round(user.numChatTokens * WORDS_PER_TOKEN) : 0} words`} placement="bottom">
            <span style={{ border: '1px solid white', padding: '5px', borderRadius: '5px' }}>
              <text>Current Chat Tokens: </text>
              <text> {user.numChatTokens} </text>
            </span>
          </Tooltip>
        </Typography>
      </Box>
      <Box className="usersettings-text">
        <Typography component="p" className="text" margin="10px">
          <span style={{ border: `1px solid ${COLORS.black}`, padding: '5px', borderRadius: '5px', display: 'inline-block', backgroundColor: `${COLORS.white}`, color: `${COLORS.black}` }}>
            <Tooltip title={`$0.05 per thousand tokens`} placement="bottom" style={{padding:'5px'}}>
              <span>
                <text>I want to <b>buy</b> </text>
                {
                  <input
                    type="number"
                    id="quantity"
                    name="quantity"
                    min={MIN_TOKENS}
                    max={MAX_TOKENS}
                    value={inputValue}
                    onChange={handleInputChange}
                    style={{ textAlign: 'center', border: '1.5px solid black', borderRadius: '5px', width: '50px', margin: '5px', fontSize: '13px' }}
                  />
                }
                <text> thousand tokens</text>
              <p style={{ margin: '5px 0px 0px 0px' }}><text> = {(inputValue ? (Number(inputValue) * 0.05) : 0).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</text></p>
              </span>
            </Tooltip>
          </span>
        </Typography>
      </Box>
      <Box className="usersettings-header">
        {/* <p>
            We sell GPT-4 tokens at a cost of $0.05 per 1000 tokens. Each token is
          roughly a word (4 characters), and this paragraph is ~26 tokens.
        </p>
        <p>
          Using our system, can pay for what you use rather than the flat $20 a
          month OpenAI offers. You may buy up to 1,000,000 tokens at a time
          (1000 sets of 1000 tokens). Tokens expire 1 year after purchase. Thank
          you for using GPT 4.1!
        </p> */}
      </Box>
      <Grid item container justifyContent="center">
        <Tooltip title={BLURB} placement="bottom">
          <PrimaryButton
            variant="contained"
            onClick={() => pay(parseInt(inputValue, 10))}
            disabled={disabled}
            value={10}
          >
            Buy Now
          </PrimaryButton>
        </Tooltip>
      </Grid>
      <Grid item container justifyContent="center">
        <Button onClick={() => navigator('/settings', { replace: true })}>
          Back to Settings
        </Button>
      </Grid>
    </div>
  );
};

// export default chatpage;
export default Payment;
