import React, { useEffect, useState } from 'react';
import { Link, NavigateFunction, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Box, Grid, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../util/redux/hooks';
import { logout as logoutApi } from '../Home/api';
import {
  logout as logoutAction,
  selectUser,
  updateChatTokens,
} from '../util/redux/userSlice';
// eslint-disable-next-line import/named
import { Message, getUserObject } from '../Chat/api';
import './usersettings.css';
import PrimaryButton from '../components/buttons/PrimaryButton';
import { sendResetPasswordEmail } from '../Authentication/api';
import ScreenGrid from '../components/ScreenGrid';
import FormGrid from '../components/form/FormGrid';
import { AlertDialog } from '../components/AlertDialog';
import '../chat.css';

const linkStyle = {
  textDecoration: 'none',
  color: 'black',
  ':hover': {
    color: 'green',
  },
};
interface PromoteButtonProps {
  admin: boolean | null;
  handleSelfPromote: () => void;
  navigator: NavigateFunction;
}

/**
 * A page only accessible to authenticated users that allows you to chat with ChatGPT-4.
 */
// eslint-disable-next-line react/function-component-definition
const UserSettings: React.FC = () => {
  const user = useAppSelector(selectUser);
  console.log(user);
  const [messages, setMessages] = useState<Message[]>([]);
  const [numChatTokens, setNumChatTokens] = useState<Message[]>([]);
  const [inputValue, setInputValue] = useState('');
  const navigator = useNavigate();
  const [admin, setAdmin] = useState(user.admin);
  const dispatch = useAppDispatch();
  // eslint-disable-next-line react-hooks/exhaustive-deps, @typescript-eslint/no-shadow
  function dispatchTokens(numChatTokens: number) {
    dispatch(
      updateChatTokens({
        email: user.email!,
        firstName: user.firstName!,
        lastName: user.lastName!,
        admin: user.admin!,
        numChatTokens,
      }),
    );
  }

  const defaultShowErrors = {
    email: false,
    alert: false,
  };
  const defaultErrorMessages = {
    email: '',
    alert: '',
  };

  // State values and hooks
  const [email, setEmail] = useState('');
  const [showError, setShowErrorState] = useState(defaultShowErrors);
  const [errorMessage, setErrorMessageState] = useState(defaultErrorMessages);
  const navigate = useNavigate();

  // Helper functions for changing only one field in a state object
  const setErrorMessage = (field: string, msg: string) => {
    setErrorMessageState((prevState) => ({
      ...prevState,
      ...{ [field]: msg },
    }));
  };
  const setShowError = (field: string, show: boolean) => {
    setShowErrorState((prevState) => ({
      ...prevState,
      ...{ [field]: show },
    }));
  };

  const alertTitle = 'Error';
  const handleAlertClose = () => {
    setShowError('alert', false);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    getUserObject(user.email!)
      .then((response) => {
        console.log(response);
        dispatchTokens(response.numChatTokens);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dispatchTokens, user.email]); // Empty dependency array to only run the effect once

  const logoutDispatch = () => dispatch(logoutAction());
  const handleLogout = async () => {
    if (await logoutApi()) {
      logoutDispatch();
      navigator('/login', { replace: true });
    }
  };

  const resetPassword = async () => {
    if (user.email != null) {
      sendResetPasswordEmail(user.email)
        .then(() => {
          handleLogout();
        })
        .catch((e) => {
          setErrorMessage('email', e.message);
          setShowError('email', true);
        });
    } else {
      setErrorMessage(
        'email',
        'No email address provided in this session. Please log in with an email address.',
      );
      setShowError('email', true);
    }
  };

  return (
    <ScreenGrid>
      <FormGrid>
        <Box className="usersettings-container">
          <Box className="usersettings-header">
            <Typography
              variant="h4"
              component="h1"
              className="gpt-heading"
              margin="10px"
            >
              <Link to="/home" className="gpt-link">
                GPT 4.1
              </Link>
            </Typography>
            <Typography
              variant="h4"
              component="h2"
              className="page-title"
            >
              User Settings
            </Typography>
          </Box>
          <Box className="usersettings-text" margin='20px'>
            <Typography
              component="p"
              className="text"
              margin="10px"
            >
              <b>Email: </b>
              {user.email}
            </Typography>
            <Typography
              component="p"
              className="text"
              margin="10px"
            >
              <b>Your Chat Tokens: </b>
              {user.numChatTokens}
            </Typography>
          </Box>
          <Grid item container justifyContent="center">
            <PrimaryButton
              variant="contained"
              onClick={() => navigator('/pay', { replace: true })}
            >
              Buy More Chat Tokens
            </PrimaryButton>
          </Grid>
          <Grid item container justifyContent="center">
            <Button onClick={() => resetPassword()}>
              Send Reset Password Email
            </Button>
          </Grid>
          <Grid item container justifyContent="center">
            <Button onClick={() => navigator('/home', { replace: true })}>
              Go Home
            </Button>
          </Grid>
        </Box>
      </FormGrid>
      {/* The alert that pops up */}
      <Grid item>
        <AlertDialog
          showAlert={showError.alert}
          title={alertTitle}
          message={errorMessage.alert}
          onClose={handleAlertClose}
        />
      </Grid>
    </ScreenGrid>
  );
};

// export default chatpage;
export default UserSettings;
